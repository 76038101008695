import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      {/* ============================ Hero Banner  Start================================== */}
      <div className='light-bg hero-banner'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
              <p className='d-inline-flex sm-small text-light rounded-5 px-2 py-1 bg-dark align-items-center'>
                We just launched our service in Mumbai, Maharashtra
                <span className='sm-small px-2 rounded-5 bg-primary text-light ms-2'>
                  New
                </span>
              </p>
              <h2>Your Trusted Partner in Indian Real Estate</h2>
              <p className='small'>
                For Owners, Businesses & Investors Worldwide.
              </p>
              <Link
                to='https://wa.me/+918830851524?text=Hi,%20I%20would%20like%20to%20connect'
                className='btn btn-success'
                title='Click here to connect with us'
              >
                <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                  <svg
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <title>WhatsApp icon</title>
                    <path
                      d='M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                Connect With Us
              </Link>
            </div>
            <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
              <div className>
                <img
                  src='assets/img/side-city-1.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Hero Banner End ================================== */}
      {/* ============================ Category Start ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Diverse Real Estate Solutions for Every Need</h2>
                <p>
                  Whether your searching for a land, home or a business space,
                  our service offers a diverse selection to meet your needs,
                  find your ideal property that aligns with your lifestyle,
                  business goals, or investment plans.
                </p>
              </div>
            </div>
          </div>

          <div className='row justify-content-center gx-3 gy-3'>
            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-success text-success fs-2'>
                    <i className='fa-solid fa-store' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Commercial</h4>
                    <p>150+ Properties</p>
                  </div>
                </a>
              </div>
            </div>

            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-warning text-warning fs-2'>
                    <i className='fa-solid fa-house-user' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Residential</h4>
                    <p>390+ Properties</p>
                  </div>
                </a>
              </div>
            </div>

            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-purple text-purple fs-2'>
                    <i className='fa-solid fa-water' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Land</h4>
                    <p>200+ Properties</p>
                  </div>
                </a>
              </div>
            </div>

            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-industry' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Industrial</h4>
                    <p>100+ Properties</p>
                  </div>
                </a>
              </div>
            </div>

            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-success text-success fs-2'>
                    <i className='fa-solid fa-vector-square' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Plots</h4>
                    <p>400+ Properties</p>
                  </div>
                </a>
              </div>
            </div>

            <div className='col-xl-2 col-lg-3 col-md-3 col-6'>
              <div className='classical-cats-wrap'>
                <a className='classical-cats-boxs' href='/'>
                  <div className='classical-cats-icon px-4 py-4 rounded bg-light-danger text-danger fs-2'>
                    <i className='fa-solid fa-sign' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Special Purpose</h4>
                    <p>100+ Properties</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='clearfix' />

      {/* ================================ Process start ======================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-10 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>How It Works – Your Property Journey Simplified</h2>
                <p>
                  Selling or dealing with a property should be seamless and
                  stress-free. At AV Realties, we simplify the process into a
                  few easy steps, ensuring transparency, efficiency, and the
                  best possible outcome for you.
                </p>
              </div>
            </div>
          </div>

          <div className='row justify-content-center g-4'>
            <div className='col-lg-3 col-md-3'>
              <div className='middle-icon-features-item'>
                <div className='icon-features-wrap'>
                  <div className='middle-icon-large-features-box f-light-success'>
                    <span className='svg-icon text-success svg-icon-2hx'>
                      <svg
                        width='45'
                        height='45'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.0021 10.9128V3.01281C13.0021 2.41281 13.5021 1.91281 14.1021 2.01281C16.1021 2.21281 17.9021 3.11284 19.3021 4.61284C20.7021 6.01284 21.6021 7.91285 21.9021 9.81285C22.0021 10.4129 21.5021 10.9128 20.9021 10.9128H13.0021Z'
                          fill='currentColor'
                        />
                        <path
                          opacity='0.3'
                          d='M11.0021 13.7128V4.91283C11.0021 4.31283 10.5021 3.81283 9.90208 3.91283C5.40208 4.51283 1.90209 8.41284 2.00209 13.1128C2.10209 18.0128 6.40208 22.0128 11.3021 21.9128C13.1021 21.8128 14.7021 21.3128 16.0021 20.4128C16.5021 20.1128 16.6021 19.3128 16.1021 18.9128L11.0021 13.7128Z'
                          fill='currentColor'
                        />
                        <path
                          opacity='0.3'
                          d='M21.9021 14.0128C21.7021 15.6128 21.1021 17.1128 20.1021 18.4128C19.7021 18.9128 19.0021 18.9128 18.6021 18.5128L13.0021 12.9128H20.9021C21.5021 12.9128 22.0021 13.4128 21.9021 14.0128Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='middle-icon-features-content'>
                  <h4>Property Evaluation & Market Analysis</h4>
                  <p>
                    We assess your property’s value based on market trends,
                    location, and unique features to ensure the best price and
                    positioning.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-3'>
              <div className='middle-icon-features-item'>
                <div className='icon-features-wrap'>
                  <div className='middle-icon-large-features-box f-light-warning'>
                    <span className='svg-icon text-warning svg-icon-2hx'>
                      <svg
                        width='45'
                        height='45'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z'
                          fill='currentColor'
                        />
                        <rect
                          opacity='0.3'
                          x='14'
                          y='4'
                          width='4'
                          height='4'
                          rx='2'
                          fill='currentColor'
                        />
                        <path
                          d='M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z'
                          fill='currentColor'
                        />
                        <rect
                          opacity='0.3'
                          x='6'
                          y='5'
                          width='6'
                          height='6'
                          rx='3'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='middle-icon-features-content'>
                  <h4>Connect with Your Real Estate Expert</h4>
                  <p>
                    Meet our experienced agents who guide you through the
                    process, offering expert insights, marketing strategies, and
                    buyer connections.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-3'>
              <div className='middle-icon-features-item'>
                <div className='icon-features-wrap'>
                  <div className='middle-icon-large-features-box f-light-blue'>
                    <span className='svg-icon text-primary svg-icon-2hx'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='35'
                        height='35'
                        fill='currentColor'
                        className='bi bi-person-video3'
                        viewBox='0 0 16 16'
                      >
                        <path d='M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5' />
                        <path d='M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.4 5.4 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2z' />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='middle-icon-features-content'>
                  <h4>Showcase & Negotiate Offers</h4>
                  <p>
                    We list and promote your property, arrange viewings, and
                    negotiate the best deal on your behalf, ensuring a smooth
                    transaction.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-3'>
              <div className='middle-icon-features-item remove'>
                <div className='icon-features-wrap'>
                  <div className='middle-icon-large-features-box f-light-purple'>
                    <span className='svg-icon text-purple svg-icon-2hx'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='45px'
                        height='45px'
                        viewBox='0 0 24 24'
                      >
                        <path
                          d='M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z'
                          fill='currentColor'
                        />
                        <path
                          d='M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='middle-icon-features-content'>
                  <h4>Seal the Deal & Handover</h4>
                  <p>
                    Once the right buyer is found, we handle the paperwork,
                    legal formalities, and ownership transfer to finalize the
                    deal effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================ Process end ======================================== */}
      <div className='clearfix' />
      {/* ================================ Category End ======================================== */}
      {/* ================================ All Property ========================================= */}
      <section className='gray-simple' id='projects'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 text-center'>
              <div className='sec-heading center'>
                <h2>Featured Real Estate Projects</h2>
                <p>
                  Explore a handpicked selection of residential, commercial,
                  land, and plot projects. Whether you&apos;re looking for a
                  dream home, a strategic land investment, a prime development
                  plot, or a high-potential commercial property, we have the
                  perfect opportunity waiting for you.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-4'>
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        Verified
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://placehold.co/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Equitable Property Group
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹78,500
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>47 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-purple text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z'
                              fill='currentColor'
                            />
                            <path
                              d='M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        SuperAgent
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://placehold.co/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Purple Flatiron House
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹67,000
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>102 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        Verified
                      </div>
                      <div className='label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z'
                              fill='currentColor'
                            />
                            <path
                              d='M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z'
                              fill='currentColor'
                            />
                            <path
                              d='M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z'
                              fill='currentColor'
                            />
                            <path
                              d='M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        New
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://placehold.co/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Rustic Reunion Tower
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹87,900
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>89 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
          </div>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
              <a
                href='/listings-list-with-sidebar'
                className='btn btn-primary px-md-5 rounded'
              >
                Browse More Properties
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Featured Property ================================== */}
      {/* ============================ Property Location Start ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Top Cities for Real Estate Investment</h2>
                <p>
                  From dynamic business districts to tranquil suburbs, explore
                  the best locations for your real estate goals in India.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-xl-4 g-md-3 g-4'>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/pune-real-estate-investment'>
                    <img
                      src='assets/img/pune.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Pune</h4>
                    <span className='text-muted-2'>120+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/pune-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/aurangabad-real-estate-investment'>
                    <img
                      src='assets/img/abad.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Ch. Sambhajinagar</h4>
                    <span className='text-muted-2'>100+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/aurangabad-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/dubai-real-estate-investment'>
                    <img
                      src='assets/img/dubai.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Dubai</h4>
                    <span className='text-muted-2'>70+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/dubai-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/mumbai-real-estate-investment'>
                    <img
                      src='assets/img/mumbai.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Mumbai</h4>
                    <span className='text-muted-2'>80+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/mumbai-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/hyderabad-real-estate-investment'>
                    <img
                      src='assets/img/hbad.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Hyderabad</h4>
                    <span className='text-muted-2'>40+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/hyderabad-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <a href='/surat-real-estate-investment'>
                    <img
                      src='assets/img/surat.jpg'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Surat</h4>
                    <span className='text-muted-2'>95+ Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <a
                      href='/surat-real-estate-investment'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
              <Link to='/' className='btn btn-primary px-md-5 rounded'>
                Browse More Locations
              </Link>
            </div>
          </div>
        </div>
      </section>
      <hr className='opacity-25' />
      {/* ============================ Property Location End ================================== */}
      {/* ============================ All Property ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Featured Property For Sale</h2>
                <p>
                  Explore our curated selection of standout properties currently
                  on the market. Discover homes that combine exceptional
                  location, unique design, and desirable amenities, all waiting
                  to be your new address.
                </p>
              </div>
            </div>
          </div>
          <div className='row list-layout'>
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <a href='/single-property-2'>
                    <img
                      src='https://placehold.co/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </a>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <a href='/single-property-2'>
                            Adobe Property Advisors
                          </a>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (42 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹120M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <a
                        href='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <a href='/single-property-2'>
                    <img
                      src='https://placehold.co/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </a>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <a href='/single-property-2'>
                            Agile Real Estate Group
                          </a>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (34 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹132M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <a
                        href='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <a href='/single-property-2'>
                    <img
                      src='https://placehold.co/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </a>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <a href='/single-property-2'>Bluebell Real Estate</a>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (124 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹127M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <a
                        href='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <a href='/single-property-2'>
                    <img
                      src='https://placehold.co/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </a>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <a href='/single-property-2'>
                            Strive Partners Realty
                          </a>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (56 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹132M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <a
                        href='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
          </div>
          {/* Pagination */}
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-4'>
              <a
                href='/listings-list-with-sidebar'
                className='btn btn-primary px-lg-5 rounded'
              >
                Browse More Properties
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Featured Property ================================== */}
      {/* ============================ Latest Property For Sale Start ================================== */}
      <section className='bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Our Achievements</h2>
                <p>
                  From transactions to happy customers, here’s what we’ve
                  achieved together.
                </p>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-xl-12 col-lg-12 col-md-12'>
              <div className='counter-info-groups'>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      ₹<span className='ctr'>500</span>CR+
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      in Property Transactions
                      <br />
                      Processed
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>2000</span>+
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Properties Under
                      <br />
                      Management
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>25+</span>
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Successful Projects
                      <br />
                      Completed
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>5000</span>+
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Happy Customers
                      <br />
                      Served
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Latest Property For Sale End ================================== */}
      {/* ============================ Smart Testimonials ================================== */}
      <section className='gray-bg'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Trusted by Investors, Businesses & Homeowners</h2>
                <p>
                  See why clients choose us for their real estate needs, from
                  first-time buyers to seasoned investors.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            {/* Single Item */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4'>
              <div className='card'>
                <div className='card-body'>
                  <div className='smart-tes-author'>
                    <div className='st-author-box justify-content-center'>
                      <div className='st-author-thumb my-3'>
                        <div className='quotes bg-primary'>
                          <i className='fa-solid fa-quote-left' />
                        </div>
                        <img
                          src='assets/img/r1.jpg'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='smart-tes-content'>
                    <p>
                      As an IT professional, I was looking for a home in a prime
                      location with easy access to business hubs and good
                      connectivity. AV Realties made the entire process smooth,
                      from shortlisting properties to handling paperwork. Their
                      expertise and transparency gave me confidence in my
                      investment.
                    </p>
                  </div>
                  <div className='st-author-info'>
                    <h4 className='st-author-title'>Sachin Jadhav</h4>
                    <span className='st-author-subtitle'>IT Proffesional</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Item */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4'>
              <div className='card'>
                <div className='card-body'>
                  <div className='smart-tes-author'>
                    <div className='st-author-box justify-content-center'>
                      <div className='st-author-thumb my-3'>
                        <div className='quotes bg-success'>
                          <i className='fa-solid fa-quote-left' />
                        </div>
                        <img
                          src='assets/img/r2.png'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='smart-tes-content'>
                    <p>
                      Finding the right commercial space for my growing business
                      was a challenge until I connected with AV Realties. They
                      understood my needs and helped me secure a property in a
                      strategic location with great potential. Their
                      professionalism and market insights made all the
                      difference!.
                    </p>
                  </div>
                  <div className='st-author-info'>
                    <h4 className='st-author-title'>Rekha Patil</h4>
                    <span className='st-author-subtitle'>Entrepreneur</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Item */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4'>
              <div className='card'>
                <div className='card-body'>
                  <div className='smart-tes-author'>
                    <div className='st-author-box justify-content-center'>
                      <div className='st-author-thumb my-3'>
                        <div className='quotes bg-purple'>
                          <i className='fa-solid fa-quote-left' />
                        </div>
                        <img
                          src='assets/img/r3.png'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='smart-tes-content'>
                    <p>
                      AV Realties helped me sell my property at the best market
                      price without any hassle. Their team managed everything
                      efficiently, from marketing to negotiations, ensuring a
                      smooth transaction. I was impressed by their dedication
                      and how they handled every detail professionally. I highly
                      recommend them.
                    </p>
                  </div>
                  <div className='st-author-info'>
                    <h4 className='st-author-title'>Naveen Jain</h4>
                    <span className='st-author-subtitle'>Owner</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Item */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4'>
              <div className='card'>
                <div className='card-body'>
                  <div className='smart-tes-author'>
                    <div className='st-author-box justify-content-center'>
                      <div className='st-author-thumb my-3'>
                        <div className='quotes bg-seegreen'>
                          <i className='fa-solid fa-quote-left' />
                        </div>
                        <img
                          src='assets/img/r4.png'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='smart-tes-content'>
                    <p>
                      As an international investor, I needed a trusted partner
                      to navigate India&apos;s real estate market. AVRealties
                      provided expert guidance, detailed market insights, and
                      helped me secure a high-yield investment. Their
                      professionalism and dedication exceeded my expectations.
                      They made the entire process seamless and stress-free!
                    </p>
                  </div>
                  <div className='st-author-info'>
                    <h4 className='st-author-title'>Adam Williams</h4>
                    <span className='st-author-subtitle'>Investor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Smart Testimonials End ================================== */}
      {/* ================================= Blog Grid ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Latest Updates By AV Realties</h2>
                <p>
                  Stay informed with the latest news and updates from AV
                  REALTIES. Discover the newest properties, market trends, and
                  insights from the forefront of real estate
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-4'>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <a href='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 july 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <a href='/blog-detail'>
                      Why people choose listio for own properties
                    </a>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <a href='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </a>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <a href='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    10 August 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <a href='/blog-detail'>
                      List of benifits and impressive AV REALTIES services
                    </a>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <a href='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </a>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <a href='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </a>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 Sep 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <a href='/blog-detail'>
                      What people says about listio properties
                    </a>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <a href='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================== Blog Grid End =============================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='bg-light'>
        <div className='container'>
          <div className='row align-items-center justify-content-center gx-5 gy-5'>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/booking.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/columbia.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/Payoneer.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/Paypal.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/razorpay.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/microsoft.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/trivago.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/visa.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/columbia.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-center'>
            <div className='col-xl-7 col-lg-11'>
              <div className='call-to-act-wrap text-center'>
                <div className='call-to-act-head mb-2'>
                  <h2 className='fs-1 mb-3 lh-sm'>
                    Subscribe &amp;
                    <br />
                    to stay updated
                  </h2>
                  <span>
                    Subscribe now to join our community to receive special
                    updates directly in your inbox.
                  </span>
                </div>
              </div>
              <div className='call-to-act-form'>
                <form className='newsletter-boxes p-2'>
                  <div className='row m-0 g-0'>
                    <div className='col-xl-10 col-9'>
                      <input
                        type='text'
                        className='form-control border-0'
                        placeholder='Your Email Address...'
                      />
                    </div>
                    <div className='col-xl-2 col-3'>
                      <button
                        type='submit'
                        className='btn btn-primary rounded-pill full-width'
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
