import React from 'react';
import { Link } from 'react-router-dom';

export default function SubmitProperty() {
  return (
    <>
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Submit Property</h2>
              <span className='ipn-subtitle'>Just Submit Your Property</span>
            </div>
          </div>
        </div>
      </div>

      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div
                className='alert bg-success text-light text-center'
                role='alert'
              >
                Hi Dear, Have you already an account?
                <Link
                  to='/'
                  className='text-warning'
                  data-bs-toggle='collapse'
                  data-bs-target='#login-frm'
                >
                  Please Login
                </Link>
              </div>
            </div>

            <div className='col-lg-12 col-md-12'>
              <div id='login-frm' className='collapse mb-5'>
                <div className='row'>
                  <div className='col-lg-5 col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <div className='input-with-icons'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Username'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-5 col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <div className='input-with-icons'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='*******'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-2 col-md-4 col-sm-12'>
                    <div className='form-group'>
                      <button
                        type='submit'
                        className='btn btn-primary full-width'
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='exclop-wrap d-flex align-items-center justify-content-between'>
                      <div className='exclop'>
                        <input
                          id='a-1'
                          className='form-check-input'
                          name='a-1'
                          type='checkbox'
                        />
                        <label htmlFor='a-1' className='form-check-label'>
                          Remember Me
                        </label>
                      </div>
                      <div className='exclop-last'>
                        <Link to='/' className='fw-medium text-primary'>
                          Forget Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='submit-page'>
                <div className='form-submit'>
                  <h3>Basic Information</h3>
                  <div className='submit-section'>
                    <div className='row'>
                      <div className='form-group col-md-12'>
                        <label>
                          Property Title
                          <span
                            className='tip-topdata'
                            data-tip='Property Title'
                          >
                            <i className='fa-solid fa-info' />
                          </span>
                        </label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Status</label>
                        <select id='status' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>For Rent</option>
                          <option value='2'>For Sale</option>
                        </select>
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Property Type</label>
                        <select id='ptypes' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>Houses</option>
                          <option value='2'>Apartment</option>
                          <option value='3'>Villas</option>
                          <option value='4'>Commercial</option>
                          <option value='5'>Offices</option>
                          <option value='6'>Garage</option>
                        </select>
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Price</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='USD'
                        />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Area</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Bedrooms</label>
                        <select id='bedrooms' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                        </select>
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Bathrooms</label>
                        <select id='bathrooms' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-submit'>
                  <h3>Gallery</h3>
                  <div className='submit-section'>
                    <div className='row'>
                      <div className='form-group col-md-12'>
                        <label>Upload Gallery</label>
                        <form
                          action='/upload-target'
                          className='dropzone dz-clickable primary-dropzone'
                        >
                          <div className='dz-default dz-message'>
                            <i className='fa-solid fa-images' />
                            <span>Drag & Drop To Change Logo</span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-submit'>
                  <h3>Location</h3>
                  <div className='submit-section'>
                    <div className='row'>
                      <div className='form-group col-md-6'>
                        <label>Address</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>City</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>State</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-6'>
                        <label>Zip Code</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-submit'>
                  <h3>Detailed Information</h3>
                  <div className='submit-section'>
                    <div className='row'>
                      <div className='form-group col-md-12'>
                        <label>Description</label>
                        <textarea className='form-control h-120' />
                      </div>

                      <div className='form-group col-md-4'>
                        <label>Building Age (optional)</label>
                        <select id='bage' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>0 - 5 Years</option>
                          <option value='2'>0 - 10Years</option>
                          <option value='3'>0 - 15 Years</option>
                          <option value='4'>0 - 20 Years</option>
                          <option value='5'>20+ Years</option>
                        </select>
                      </div>

                      <div className='form-group col-md-4'>
                        <label>Garage (optional)</label>
                        <select id='garage' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                        </select>
                      </div>

                      <div className='form-group col-md-4'>
                        <label>Rooms (optional)</label>
                        <select id='rooms' className='form-control'>
                          <option value=''>&nbsp;</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                        </select>
                      </div>

                      <div className='form-group col-md-12'>
                        <label>Other Features (optional)</label>
                        <div className='o-features'>
                          <ul className='no-ul-list third-row'>
                            <li>
                              <input
                                id='a-1'
                                className='form-check-input'
                                name='a-1'
                                type='checkbox'
                              />
                              <label htmlFor='a-1' className='form-check-label'>
                                Air Condition
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-2'
                                className='form-check-input'
                                name='a-2'
                                type='checkbox'
                              />
                              <label htmlFor='a-2' className='form-check-label'>
                                Bedding
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-3'
                                className='form-check-input'
                                name='a-3'
                                type='checkbox'
                              />
                              <label htmlFor='a-3' className='form-check-label'>
                                Heating
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-4'
                                className='form-check-input'
                                name='a-4'
                                type='checkbox'
                              />
                              <label htmlFor='a-4' className='form-check-label'>
                                Internet
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-5'
                                className='form-check-input'
                                name='a-5'
                                type='checkbox'
                              />
                              <label htmlFor='a-5' className='form-check-label'>
                                Microwave
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-6'
                                className='form-check-input'
                                name='a-6'
                                type='checkbox'
                              />
                              <label htmlFor='a-6' className='form-check-label'>
                                Smoking Allow
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-7'
                                className='form-check-input'
                                name='a-7'
                                type='checkbox'
                              />
                              <label htmlFor='a-7' className='form-check-label'>
                                Terrace
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-8'
                                className='form-check-input'
                                name='a-8'
                                type='checkbox'
                              />
                              <label htmlFor='a-8' className='form-check-label'>
                                Balcony
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-9'
                                className='form-check-input'
                                name='a-9'
                                type='checkbox'
                              />
                              <label htmlFor='a-9' className='form-check-label'>
                                Icon
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-10'
                                className='form-check-input'
                                name='a-10'
                                type='checkbox'
                              />
                              <label
                                htmlFor='a-10'
                                className='form-check-label'
                              >
                                Wi-Fi
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-11'
                                className='form-check-input'
                                name='a-11'
                                type='checkbox'
                              />
                              <label
                                htmlFor='a-11'
                                className='form-check-label'
                              >
                                Beach
                              </label>
                            </li>
                            <li>
                              <input
                                id='a-12'
                                className='form-check-input'
                                name='a-12'
                                type='checkbox'
                              />
                              <label
                                htmlFor='a-12'
                                className='form-check-label'
                              >
                                Parking
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-submit'>
                  <h3>Contact Information</h3>
                  <div className='submit-section'>
                    <div className='row'>
                      <div className='form-group col-md-4'>
                        <label>Name</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-4'>
                        <label>Email</label>
                        <input type='text' className='form-control' />
                      </div>

                      <div className='form-group col-md-4'>
                        <label>Phone (optional)</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group col-lg-12 col-md-12'>
                  <label>GDPR Agreement *</label>
                  <ul className='no-ul-list'>
                    <li>
                      <input
                        id='aj-1'
                        className='form-check-input'
                        name='aj-1'
                        type='checkbox'
                      />
                      <label htmlFor='aj-1' className='form-check-label'>
                        I consent to having this website store my submitted
                        information so they can respond to my inquiry.
                      </label>
                    </li>
                  </ul>
                </div>

                <div className='form-group col-lg-12 col-md-12'>
                  <button
                    className='btn btn-primary fw-medium px-5'
                    type='button'
                  >
                    Submit & Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
