import React from 'react';
import { Link } from 'react-router-dom';

export default function Dubai() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Dubai</h2>
                  <p>
                    Dubai, one of the seven emirates of the United Arab
                    Emirates, has transformed from a modest trading post into a
                    global hub for business, tourism, and luxury living. Known
                    for its iconic skyline, world-class infrastructure, and
                    cosmopolitan lifestyle, Dubai offers a unique blend of
                    tradition and modernity.
                  </p>
                  <img
                    src='https://meconstructionnews.com/wp-content/uploads/2022/11/Dubai-City_1000x600.jpg'
                    alt='Historical Dubai'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    Historically, Dubai was a center for fishing and pearl
                    diving. The discovery of oil in the 1960s catalyzed rapid
                    development, but visionary leadership recognized the
                    importance of diversifying the economy. Investments in
                    trade, tourism, and real estate have since propelled Dubai
                    onto the world stage.
                  </p>
                  <img
                    src='https://dubaitaliaexperiences.com/assets/w=1500&h=740&fit=fill&f=center___images.ctfassets.net_7dc7gq8ix1ml_4ZTYuKCt6XKAoLQU6ukweR_9fb85d776b8d1619c8595611cf060ba1_vecchia_dubai.jpg'
                    alt='Dubai Heritage'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Strategically located at the crossroads of Europe, Asia, and
                    Africa, Dubai serves as a central hub for international
                    trade and travel. Its world-class airports and seaports
                    facilitate seamless connectivity, making it an attractive
                    destination for businesses and investors.
                  </p>
                  <img
                    src='https://imgcdn.flamingotravels.co.in/Images/City/dubai-map.jpg'
                    alt='Dubai Geography'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    Dubai&apos;s economy is diverse, with sectors like tourism,
                    finance, real estate, and trade contributing significantly.
                    The city&apos;s commitment to innovation is evident in
                    projects like the Dubai 2040 Urban Master Plan, aiming to
                    enhance residents quality of life and solidify Dubai&apos;s
                    position as a global destination.
                  </p>
                  <img
                    src='https://www.webuildvalue.com/wp-content/uploads/2021/11/Dubai-metro.jpg'
                    alt='Dubai Infrastructure'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Why Invest in Dubai Real Estate?</h3>
                  <ul>
                    <li>
                      <strong>Tax-Free Income:</strong> Dubai offers a tax-free
                      environment, allowing investors to enjoy full rental
                      income and capital gains.
                    </li>
                    <li>
                      <strong>High Rental Yields:</strong> Investors can expect
                      steady income from rental properties due to the
                      city&apos;s continuous population growth and robust
                      tourism sector.{' '}
                    </li>
                    <li>
                      <strong>Stable and Regulated Market:</strong> The Dubai
                      government has implemented measures to stabilize and
                      develop the market, ensuring a secure investment
                      environment.{' '}
                    </li>
                    <li>
                      <strong>Diverse Property Options:</strong> From luxurious
                      villas to affordable apartments, Dubai&apos;s real estate
                      market caters to various investment preferences.{' '}
                    </li>
                    <li>
                      <strong>Strategic Location:</strong> Serving as a central
                      hub for international trade and travel, Dubai&apos;s
                      location enhances its appeal to global investors.{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3 className='mt-5'>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/create-account' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
