import React from 'react';
import { Link } from 'react-router-dom';

export default function Hyderabad() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Hyderabad</h2>
                  <p>
                    Hyderabad, the capital city of Telangana, is a vibrant
                    metropolis known for its rich history, diverse culture, and
                    rapid economic growth. Often referred to as the City of
                    Pearls Hyderabad seamlessly blends its historical charm with
                    modern infrastructure.
                  </p>
                  <img
                    src='https://wallpapercave.com/wp/wp10257499.jpg'
                    alt='Hyderabad Cityscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    Founded in 1591 by Muhammad Quli Qutb Shah, Hyderabad boasts
                    a rich tapestry of history. Landmarks such as the Charminar
                    and Golconda Fort stand as testaments to its glorious past,
                    attracting tourists and history enthusiasts from around the
                    world.
                  </p>
                  <img
                    src='https://www.fabhotels.com/blog/wp-content/uploads/2018/10/1000x650-72.jpg'
                    alt='Hyderabad Heritage Site'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Strategically located in south-central India, Hyderabad
                    serves as a bridge between the northern and southern regions
                    of the country. Its central location offers excellent
                    connectivity via road, rail, and air, making it a preferred
                    destination for businesses and investors.
                  </p>
                  <img
                    src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Hyderabad_Elevation_Model.png/500px-Hyderabad_Elevation_Model.png'
                    alt='Hyderabad Landscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    Hyderabad has emerged as a major IT and pharmaceutical hub,
                    housing numerous multinational companies and research
                    institutions. The development of HITEC City and Genome
                    Valley has propelled the city&apos;s economic growth,
                    leading to increased demand for commercial and residential
                    properties. Infrastructure projects like the Outer Ring Road
                    and the Hyderabad Metro have further enhanced connectivity
                    and urban development.
                  </p>
                  <img
                    src='https://nestcon.com/wp-content/uploads/2025/01/The-Impact-of-Infrastructure-Projects-on-Hyderabads-Real-Estate-Market-min.png'
                    alt='Hyderabad Infrastructure'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Why Invest in Hyderabad Real Estate?</h3>
                  <ul>
                    <li>
                      <strong>Thriving IT Industry:</strong> The presence of
                      global tech giants ensures a steady influx of
                      professionals, boosting demand for housing.
                    </li>
                    <li>
                      <strong>Affordable Property Prices:</strong> Compared to
                      other metropolitan cities, Hyderabad offers competitive
                      property rates with promising appreciation potential.
                    </li>
                    <li>
                      <strong>Robust Infrastructure:</strong> Continuous
                      development in transportation and public amenities
                      enhances the quality of life and property values.
                    </li>
                    <li>
                      <strong>Cultural Diversity:</strong> A harmonious blend of
                      tradition and modernity makes Hyderabad an attractive
                      place to live and work.
                    </li>
                    <li>
                      <strong>Favorable Government Policies:</strong>{' '}
                      Investor-friendly regulations and initiatives promote a
                      conducive environment for real estate investments.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3 className='mt-5'>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/create-account' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
