import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';
import PlausibleProvider from './layouts/PlausibleProvider';
import Layout from './layouts/Layout';
import Home from './pages/Home';
import SubmitProperty from './pages/SubmitProperty';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import About from './pages/About';
import Faq from './pages/Faq';
import Checkout from './pages/Checkout';
import CreateAccount from './pages/CreateAccount';
import PropertyDetail from './pages/PropertyDetail';
import SinglePropertyOne from './pages/SinglePropertyOne';
import SinglePropertyTwo from './pages/SinglePropertyTwo';
import BlogDetail from './pages/BlogDetail';
import ListingListWithSidebar from './pages/ListingListWithSidebar';
import GridLayoutWithSidebar from './pages/GridLayoutWithSidebar';
import AgentPage from './pages/AgentPage';
import Agents from './pages/Agents';
import ErrorPage from './pages/ErrorPage';
import Pune from './pages/Pune';
import Aurangabad from './pages/Aurangabad';
import Dubai from './pages/Dubai';
import Mumbai from './pages/Mumbai';
import Hyderabad from './pages/Hyderabad';
import Surat from './pages/Surat';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<PlausibleProvider />}>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='submit-property' element={<SubmitProperty />} />
        <Route path='blog' element={<Blog />} />
        <Route path='blog-detail' element={<BlogDetail />} />
        <Route path='checkout' element={<Checkout />} />
        <Route path='contact' element={<Contact />} />
        <Route path='about-us' element={<About />} />
        <Route path='faq' element={<Faq />} />
        <Route path='create-account' element={<CreateAccount />} />
        <Route path='single-property-1' element={<SinglePropertyOne />} />
        <Route path='property-detail' element={<PropertyDetail />} />
        <Route path='single-property-2' element={<SinglePropertyTwo />} />
        <Route
          path='listings-list-with-sidebar'
          element={<ListingListWithSidebar />}
        />
        <Route
          path='grid-layout-with-sidebar'
          element={<GridLayoutWithSidebar />}
        />
        <Route path='agent-page' element={<AgentPage />} />
        <Route path='agents' element={<Agents />} />
        <Route path='pune-real-estate-investment' element={<Pune />} />
        <Route
          path='aurangabad-real-estate-investment'
          element={<Aurangabad />}
        />
        <Route path='dubai-real-estate-investment' element={<Dubai />} />
        <Route path='mumbai-real-estate-investment' element={<Mumbai />} />
        <Route
          path='hyderabad-real-estate-investment'
          element={<Hyderabad />}
        />
        <Route path='surat-real-estate-investment' element={<Surat />} />
        {/* Fallback route */}
        <Route path='*' element={<ErrorPage />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
