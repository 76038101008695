import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 text-center'>
              <h2 className='ipt-title'>About AV REALTIES</h2>
              <span className='ipn-subtitle'>
                Delivering excellence in real estate with trust, expertise, and
                long-term vision.
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Our Story Start ================================== */}
      <section>
        <div className='container'>
          {/* row Start */}
          <div className='row align-items-center'>
            <div className='col-lg-12 col-md-12'>
              <div className='story-wrap explore-content'>
                <div className='container content'>
                  <p>
                    AV Realties is a premier{' '}
                    <strong>Indian and international real estate agency</strong>{' '}
                    headquartered in <strong>Aurangabad, Maharashtra</strong>.
                    With over <strong>30 years of industry expertise</strong>,
                    we specialize in{' '}
                    <strong>
                      land management, real estate sales, and property
                      investment solutions
                    </strong>
                    .
                  </p>
                  <p>
                    We are committed to providing seamless, transparent, and
                    rewarding real estate transactions, ensuring our clients
                    achieve their property goals with confidence.
                  </p>

                  <h3 className='mt-5'>Our Expertise & Services</h3>
                  <ul className='list-group list-group-flush'>
                    <li className='list-group-item'>
                      <strong>Land Acquisition & Management:</strong> Expert
                      solutions in identifying, developing, and managing land
                      investments.
                    </li>
                    <li className='list-group-item'>
                      <strong>Real Estate Sales:</strong> Assisting buyers and
                      sellers in making informed investment decisions.
                    </li>
                    <li className='list-group-item'>
                      <strong>Property Management:</strong> Comprehensive
                      property services to enhance ownership experiences.
                    </li>
                    <li className='list-group-item'>
                      <strong>Investment Consultation:</strong> Providing
                      strategic real estate investment insights.
                    </li>
                    <li className='list-group-item'>
                      <strong>Tailored Marketing Strategies:</strong>{' '}
                      Cost-effective, customized marketing for property
                      promotions.
                    </li>
                  </ul>

                  <h3 className='mt-5'>Meet Our Founder</h3>
                  <p className='mb-4'>
                    AV Realties is led by a{' '}
                    <strong>seasoned entrepreneur</strong> with over{' '}
                    <strong>25 years of experience</strong> in land acquisition,
                    development, and industrial operations. His expertise spans
                    multiple industries, including metal fabrication for
                    agriculture and business operations, driving growth and
                    innovation.
                  </p>

                  <h3 className='mt-5'>Our Core Values</h3>
                  <div className='row'>
                    <div className='col-md-6'>
                      <ul className='list-group'>
                        <li className='list-group-item'>
                          🏆 <strong>Long-Term Thinking</strong> – Sustainable
                          growth and lasting relationships.
                        </li>
                        <li className='list-group-item'>
                          🤝 <strong>Trust & Autonomy</strong> – Transparent and
                          honest interactions.
                        </li>
                        <li className='list-group-item'>
                          📈 <strong>Grow Together</strong> – Our success is
                          intertwined with our clients’ success.
                        </li>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <ul className='list-group'>
                        <li className='list-group-item'>
                          ⚡ <strong>Bias for Action</strong> – Proactively
                          seizing opportunities.
                        </li>
                        <li className='list-group-item'>
                          👨‍👩‍👧 <strong>Customer First</strong> – Prioritizing
                          client needs and satisfaction.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h3 className='mt-5'>Why Choose AV Realties?</h3>
                  <ul className='list-group list-group-flush'>
                    <li className='list-group-item'>
                      ✔ <strong>Market Knowledge:</strong> Deep insights into
                      local and global real estate trends.
                    </li>
                    <li className='list-group-item'>
                      ✔ <strong>Investment Strategies:</strong> Customized plans
                      for sustainable returns.
                    </li>
                    <li className='list-group-item'>
                      ✔ <strong>Proven Track Record:</strong> High levels of
                      repeat and referral business.
                    </li>
                    <li className='list-group-item'>
                      ✔ <strong>Seamless Client Experience:</strong> Hassle-free
                      property transactions and management.
                    </li>
                  </ul>

                  <h3 className='mt-5 text-center'>
                    Let’s Build Your Real Estate Future Together
                  </h3>
                  <p className='text-center'>
                    Whether you’re looking to{' '}
                    <strong>buy, sell, or invest</strong>, AV Realties is here
                    to guide you every step of the way.
                  </p>
                  <div className='text-center'>
                    <Link
                      to='https://wa.me/+918830851524?text=Hi,%20I%20would%20like%20to%20connect'
                      className='btn btn-success'
                      title='Click here to connect with us'
                    >
                      <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                        <svg
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                          role='img'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <title>WhatsApp icon</title>
                          <path
                            d='M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      Get in Touch With Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
      </section>
      {/* ============================ Our Story End ================================== */}
      {/* ================= Our Team================= */}
      <section className='gray-bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='sec-heading center'>
                <h2>The AV Realties Team</h2>
                <p>
                  A team of seasoned professionals committed to delivering
                  excellence in real estate advisory, sales, and property
                  management.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* Single Team */}
            <div className='single-team col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='team-grid'>
                <div className='teamgrid-user'>
                  <img
                    src='https://placehold.co/800x800'
                    alt=''
                    className='img-fluid'
                  />
                </div>
                <div className='teamgrid-content'>
                  <h4>Anil Varma</h4>
                  <span>Founder & Managing Partner</span>
                </div>
                <div className='teamgrid-social'>
                  <ul>
                    <li>
                      <Link to='/' className='f-cl'>
                        <i className='fa-brands fa-facebook' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='t-cl'>
                        <i className='fa-brands fa-twitter' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='i-cl'>
                        <i className='fa-brands fa-instagram' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='l-cl'>
                        <i className='fa-brands fa-linkedin' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Single Teamm */}
            <div className='single-team col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='team-grid'>
                <div className='teamgrid-user'>
                  <img
                    src='https://placehold.co/800x800'
                    alt=''
                    className='img-fluid'
                  />
                </div>
                <div className='teamgrid-content'>
                  <h4>Atul Deshmukh</h4>
                  <span>Senior Partner & Sales Head</span>
                </div>
                <div className='teamgrid-social'>
                  <ul>
                    <li>
                      <Link to='/' className='f-cl'>
                        <i className='fa-brands fa-facebook' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='t-cl'>
                        <i className='fa-brands fa-twitter' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='i-cl'>
                        <i className='fa-brands fa-instagram' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='l-cl'>
                        <i className='fa-brands fa-linkedin' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Single Teamm */}
            <div className='single-team col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='team-grid'>
                <div className='teamgrid-user'>
                  <img
                    src='https://placehold.co/800x800'
                    alt=''
                    className='img-fluid'
                  />
                </div>
                <div className='teamgrid-content'>
                  <h4>Piyush Varma</h4>
                  <span>Senior Partner</span>
                </div>
                <div className='teamgrid-social'>
                  <ul>
                    <li>
                      <Link to='/' className='f-cl'>
                        <i className='fa-brands fa-facebook' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='t-cl'>
                        <i className='fa-brands fa-twitter' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='i-cl'>
                        <i className='fa-brands fa-instagram' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='l-cl'>
                        <i className='fa-brands fa-linkedin' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Single Teamm */}
            <div className='single-team col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='team-grid'>
                <div className='teamgrid-user'>
                  <img
                    src='https://placehold.co/800x800'
                    alt=''
                    className='img-fluid'
                  />
                </div>
                <div className='teamgrid-content'>
                  <h4>Anil Patel</h4>
                  <span>Senior Partner</span>
                </div>
                <div className='teamgrid-social'>
                  <ul>
                    <li>
                      <Link to='/' className='f-cl'>
                        <i className='fa-brands fa-facebook' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='t-cl'>
                        <i className='fa-brands fa-twitter' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='i-cl'>
                        <i className='fa-brands fa-instagram' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/' className='l-cl'>
                        <i className='fa-brands fa-linkedin' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== Our Team ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
