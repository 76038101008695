import React from 'react';
import { Link } from 'react-router-dom';

export default function Surat() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Surat</h2>
                  <p>
                    Surat, located in the western Indian state of Gujarat, is a
                    bustling metropolis known for its rapid economic growth and
                    vibrant culture. Situated on the banks of the Tapti River
                    near the Arabian Sea, Surat has evolved from a historic port
                    city into a major commercial hub.
                  </p>
                  <img
                    src='https://i.ytimg.com/vi/ilXmOihy09c/maxresdefault.jpg'
                    alt='Surat Cityscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    Historically, Surat was one of India&apos;s most important
                    port cities, especially during the Mughal era, serving as a
                    gateway for trade and commerce. Its rich heritage is evident
                    in landmarks such as the Surat Castle and ancient temples,
                    reflecting a blend of cultural influences over centuries.
                  </p>
                  <img
                    src='https://www.gingerhotels.com/resourcefiles/blogsmallimages/surat.jpg'
                    alt='Surat Heritage Site'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Strategically positioned along the Tapti River and close to
                    the Arabian Sea, Surat offers excellent connectivity to
                    major cities like Mumbai and Ahmedabad. Its location has
                    historically made it a pivotal center for trade, and ongoing
                    infrastructure projects continue to enhance its
                    accessibility.
                  </p>
                  <img
                    src='https://www.researchgate.net/profile/Abhishek-Nair-7/publication/267865579/figure/fig5/AS:295312116731909@1447419303645/Urban-expansion-of-Surat-City.png'
                    alt='Surat Landscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    Surat boasts a robust economy, primarily driven by its
                    diamond and textile industries. Remarkably, about 90% of the
                    world&apos;s diamonds are cut and polished in Surat, earning
                    it the title Diamond City of the World. Additionally, its
                    textile sector, especially silk manufacturing, has garnered
                    national acclaim. The city&apos;s infrastructure is
                    continually evolving, with projects like the Diamond
                    Research and Mercantile City (DREAM City) and the Surat
                    Metro enhancing its urban landscape.
                  </p>
                  <img
                    src='https://metrorailtoday.com/assets/uploads/gallary/20230131125402.jpg'
                    alt='Surat Infrastructure'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Why Invest in Surat Real Estate?</h3>
                  <ul>
                    <li>
                      <strong>Thriving Industrial Base:</strong> The dominance
                      of diamond and textile industries ensures a steady demand
                      for commercial and residential properties.
                    </li>
                    <li>
                      <strong>Rapid Urbanization:</strong> As one of
                      India&apos;s fastest-growing cities, Surat offers
                      promising prospects for real estate appreciation.
                    </li>
                    <li>
                      <strong>Infrastructure Development:</strong> Initiatives
                      like the Surat Metro and DREAM City project are set to
                      boost connectivity and urban living standards.
                    </li>
                    <li>
                      <strong>High Rental Yields:</strong> The influx of
                      professionals and businesses contributes to attractive
                      rental returns for property investors.
                    </li>
                    <li>
                      <strong>Quality of Life:</strong> Surat&apos;s blend of
                      modern amenities, cultural heritage, and proactive
                      governance makes it an appealing destination for residents
                      and investors alike.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3 className='mt-5'>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
