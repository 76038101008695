import React from 'react';
import { Link } from 'react-router-dom';

export default function SinglePropertyOne() {
  return (
    <div>
      {/* ============================ Hero Banner  Start================================== */}
      <div className='featured_slick_gallery gray'>
        <div className='featured_slick_gallery-slide'>
          <div className='featured_slick_padd'>
            <a
              href='https://via.placeholder.com/1200x850'
              className='mfp-gallery'
            >
              <img
                src='https://via.placeholder.com/1200x850'
                className='img-fluid mx-auto'
                alt=''
              />
            </a>
          </div>
          <div className='featured_slick_padd'>
            <a
              href='https://via.placeholder.com/1200x850'
              className='mfp-gallery'
            >
              <img
                src='https://via.placeholder.com/1200x850'
                className='img-fluid mx-auto'
                alt=''
              />
            </a>
          </div>
          <div className='featured_slick_padd'>
            <a
              href='https://via.placeholder.com/1200x850'
              className='mfp-gallery'
            >
              <img
                src='https://via.placeholder.com/1200x850'
                className='img-fluid mx-auto'
                alt=''
              />
            </a>
          </div>
          <div className='featured_slick_padd'>
            <a
              href='https://via.placeholder.com/1200x850'
              className='mfp-gallery'
            >
              <img
                src='https://via.placeholder.com/1200x850'
                className='img-fluid mx-auto'
                alt=''
              />
            </a>
          </div>
        </div>
        <Link to='/' className='btn-view-pic'>
          View photos
        </Link>
      </div>
      {/* ============================ Hero Banner End ================================== */}
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-8 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <span className='label text-light bg-success'>For Sale</span>
                  <h3>Jannat Graynight Mood In Siver Colony, London</h3>
                  <span>
                    <i className='lni-map-marker' /> 778 Country St. Panama
                    City, FL
                  </span>
                  <h3 className='prt-price-fix text-primary'>
                    $7,600<sub>/month</sub>
                  </h3>
                  <div className='list-fx-features'>
                    <div className='listing-card-info-icon'>
                      <div className='inc-fleat-icon me-1'>
                        <img src='assets/img/bed.svg' width={13} alt='' />
                      </div>
                      3 Beds
                    </div>
                    <div className='listing-card-info-icon'>
                      <div className='inc-fleat-icon me-1'>
                        <img src='assets/img/bathtub.svg' width={13} alt='' />
                      </div>
                      1 Bath
                    </div>
                    <div className='listing-card-info-icon'>
                      <div className='inc-fleat-icon me-1'>
                        <img src='assets/img/move.svg' width={13} alt='' />
                      </div>
                      800 sqft
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#features'
                    data-bs-target='#clOne'
                    aria-controls='clOne'
                    to='/'
                    aria-expanded='false'
                  >
                    <h4 className='property_block_title'>
                      Detail &amp; Features
                    </h4>
                  </Link>
                </div>
                <div
                  id='clOne'
                  className='panel-collapse collapse show'
                  aria-labelledby='clOne'
                >
                  <div className='block-body'>
                    <ul className='deatil_features'>
                      <li>
                        <strong>Bedrooms:</strong>3 Beds
                      </li>
                      <li>
                        <strong>Bathrooms:</strong>2 Bath
                      </li>
                      <li>
                        <strong>Areas:</strong>4,240 sq ft
                      </li>
                      <li>
                        <strong>Garage</strong>1
                      </li>
                      <li>
                        <strong>Property Type:</strong>Apartment
                      </li>
                      <li>
                        <strong>Year:</strong>Built1982
                      </li>
                      <li>
                        <strong>Status:</strong>Active
                      </li>
                      <li>
                        <strong>Cooling:</strong>Central A/C
                      </li>
                      <li>
                        <strong>Heating Type:</strong>Forced Air
                      </li>
                      <li>
                        <strong>Kitchen Features:</strong>Kitchen Facilities
                      </li>
                      <li>
                        <strong>Exterior:</strong>FinishBrick
                      </li>
                      <li>
                        <strong>Swimming Pool:</strong>Yes
                      </li>
                      <li>
                        <strong>Elevetor:</strong>Yes
                      </li>
                      <li>
                        <strong>Fireplace:</strong>Yes
                      </li>
                      <li>
                        <strong>Free WiFi:</strong>No
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#dsrp'
                    data-bs-target='#clTwo'
                    aria-controls='clTwo'
                    to='/'
                    aria-expanded='true'
                  >
                    <h4 className='property_block_title'>Description</h4>
                  </Link>
                </div>
                <div id='clTwo' className='panel-collapse collapse show'>
                  <div className='block-body'>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      dont look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isnt anything embarrassing hidden in the middle of text.
                      All the Lorem Ipsum generators on the Internet tend to
                      repeat predefined chunks as necessary, making this the
                      first true generator on the Internet.
                    </p>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#amen'
                    data-bs-target='#clThree'
                    aria-controls='clThree'
                    to='/'
                    aria-expanded='true'
                  >
                    <h4 className='property_block_title'>Ameneties</h4>
                  </Link>
                </div>
                <div id='clThree' className='panel-collapse collapse show'>
                  <div className='block-body'>
                    <ul className='avl-features third color'>
                      <li>Air Conditioning</li>
                      <li>Swimming Pool</li>
                      <li>Central Heating</li>
                      <li>Laundry Room</li>
                      <li>Gym</li>
                      <li>Alarm</li>
                      <li>Window Covering</li>
                      <li>Internet</li>
                      <li>Pets Allow</li>
                      <li>Free WiFi</li>
                      <li>Car Parking</li>
                      <li>Spa &amp; Massage</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#vid'
                    data-bs-target='#clFour'
                    aria-controls='clFour'
                    to='/'
                    aria-expanded='true'
                    className='collapsed'
                  >
                    <h4 className='property_block_title'>Property video</h4>
                  </Link>
                </div>
                <div id='clFour' className='panel-collapse collapse'>
                  <div className='block-body'>
                    <div className='property_video'>
                      <div className='thumb'>
                        <img
                          className='pro_img img-fluid w100'
                          src='https://via.placeholder.com/900x700'
                          alt='7.jpg'
                        />
                        <div className='overlay_icon'>
                          <div className='bb-video-box'>
                            <div className='bb-video-box-inner'>
                              <div className='bb-video-box-innerup'>
                                <a
                                  href='https://www.youtube.com/watch?v=A8EI6JaFbv4'
                                  data-bs-toggle='modal'
                                  data-bs-target='#popup-video'
                                  className='text-primary'
                                >
                                  <i className='fa-solid fa-play' />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#floor'
                    data-bs-target='#clFive'
                    aria-controls='clFive'
                    to='/'
                    aria-expanded='true'
                    className='collapsed'
                  >
                    <h4 className='property_block_title'>Floor Plan</h4>
                  </Link>
                </div>
                <div id='clFive' className='panel-collapse collapse'>
                  <div className='block-body'>
                    <div className='accordion' id='floor-option'>
                      <div className='card'>
                        <div className='card-header' id='firstFloor'>
                          <h2 className='mb-0'>
                            <button
                              type='button'
                              className='btn btn-link'
                              data-bs-toggle='collapse'
                              data-bs-target='#firstfloor'
                              aria-controls='firstfloor'
                            >
                              First Floor<span>740 sq ft</span>
                            </button>
                          </h2>
                        </div>
                        <div
                          id='firstfloor'
                          className='collapse'
                          aria-labelledby='firstFloor'
                          data-parent='#floor-option'
                        >
                          <div className='card-body'>
                            <img
                              src='assets/img/floor.jpg'
                              className='img-fluid'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                      <div className='card'>
                        <div className='card-header' id='seconfFloor'>
                          <h2 className='mb-0'>
                            <button
                              type='button'
                              className='btn btn-link collapsed'
                              data-bs-toggle='collapse'
                              data-bs-target='#secondfloor'
                              aria-controls='secondfloor'
                            >
                              Second Floor<span>710 sq ft</span>
                            </button>
                          </h2>
                        </div>
                        <div
                          id='secondfloor'
                          className='collapse'
                          aria-labelledby='seconfFloor'
                          data-parent='#floor-option'
                        >
                          <div className='card-body'>
                            <img
                              src='assets/img/floor.jpg'
                              className='img-fluid'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                      <div className='card'>
                        <div className='card-header' id='third-garage'>
                          <h2 className='mb-0'>
                            <button
                              type='button'
                              className='btn btn-link collapsed'
                              data-bs-toggle='collapse'
                              data-bs-target='#garages'
                              aria-controls='garages'
                            >
                              Garage<span>520 sq ft</span>
                            </button>
                          </h2>
                        </div>
                        <div
                          id='garages'
                          className='collapse'
                          aria-labelledby='third-garage'
                          data-parent='#floor-option'
                        >
                          <div className='card-body'>
                            <img
                              src='assets/img/floor.jpg'
                              className='img-fluid'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#loca'
                    data-bs-target='#clSix'
                    aria-controls='clSix'
                    to='/'
                    aria-expanded='true'
                    className='collapsed'
                  >
                    <h4 className='property_block_title'>Location</h4>
                  </Link>
                </div>
                <div id='clSix' className='panel-collapse collapse'>
                  <div className='block-body'>
                    <div className='map-container'>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.3838103135677!2d80.87929001488125!3d26.827742183164247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfe8bc34b51bb%3A0xa3ca86eec63f6f8!2sINFOSYS%20DIGITAL%20COMPUTER%20(Prabhat%20Computer%20Classes)!5e0!3m2!1sen!2sin!4v1680238790732!5m2!1sen!2sin'
                        width='100%'
                        height={450}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                        title='AV REALTIES'
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#clSev'
                    data-bs-target='#clSev'
                    aria-controls='clOne'
                    to='/'
                    aria-expanded='true'
                    className='collapsed'
                  >
                    <h4 className='property_block_title'>Gallery</h4>
                  </Link>
                </div>
                <div id='clSev' className='panel-collapse collapse'>
                  <div className='block-body'>
                    <ul className='list-gallery-inline'>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://via.placeholder.com/1200x850'
                          className='mfp-gallery'
                        >
                          <img
                            src='https://via.placeholder.com/1200x850'
                            className='img-fluid mx-auto'
                            alt=''
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* All over Review */}
              <div className='rating-overview'>
                <div className='rating-overview-box'>
                  <span className='rating-overview-box-total'>4.2</span>
                  <span className='rating-overview-box-percent'>
                    out of 5.0
                  </span>
                  <div className='star-rating' data-rating={5}>
                    <i className='fas fa-star fs-xs mx-1' />
                    <i className='fas fa-star fs-xs mx-1' />
                    <i className='fas fa-star fs-xs mx-1' />
                    <i className='fas fa-star fs-xs mx-1' />
                    <i className='fas fa-star fs-xs mx-1' />
                  </div>
                </div>
                <div className='rating-bars'>
                  <div className='rating-bars-item'>
                    <span className='rating-bars-name'>Service</span>
                    <span className='rating-bars-inner'>
                      <span
                        className='rating-bars-rating high'
                        data-rating='4.7'
                      >
                        <span
                          className='rating-bars-rating-inner'
                          style={{ width: '85%' }}
                        />
                      </span>
                      <strong>4.7</strong>
                    </span>
                  </div>
                  <div className='rating-bars-item'>
                    <span className='rating-bars-name'>Value for Money</span>
                    <span className='rating-bars-inner'>
                      <span
                        className='rating-bars-rating good'
                        data-rating='3.9'
                      >
                        <span
                          className='rating-bars-rating-inner'
                          style={{ width: '75%' }}
                        />
                      </span>
                      <strong>3.9</strong>
                    </span>
                  </div>
                  <div className='rating-bars-item'>
                    <span className='rating-bars-name'>Location</span>
                    <span className='rating-bars-inner'>
                      <span
                        className='rating-bars-rating mid'
                        data-rating='3.2'
                      >
                        <span
                          className='rating-bars-rating-inner'
                          style={{ width: '52.2%' }}
                        />
                      </span>
                      <strong>3.2</strong>
                    </span>
                  </div>
                  <div className='rating-bars-item'>
                    <span className='rating-bars-name'>Cleanliness</span>
                    <span className='rating-bars-inner'>
                      <span
                        className='rating-bars-rating poor'
                        data-rating={2.0}
                      >
                        <span
                          className='rating-bars-rating-inner'
                          style={{ width: '20%' }}
                        />
                      </span>
                      <strong>2.0</strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* All over Review */}
              {/* Single Reviews Block */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#rev'
                    data-bs-target='#clEight'
                    aria-controls='clEight'
                    to='/'
                    aria-expanded='true'
                  >
                    <h4 className='property_block_title'>102 Reviews</h4>
                  </Link>
                </div>
                <div id='clEight' className='panel-collapse collapse show'>
                  <div className='block-body'>
                    <div className='author-review'>
                      <div className='comment-list'>
                        <ul>
                          <li className='article_comments_wrap'>
                            <article>
                              <div className='article_comments_thumb'>
                                <img
                                  src='https://via.placeholder.com/800x800'
                                  alt=''
                                />
                              </div>
                              <div className='comment-details'>
                                <div className='comment-meta'>
                                  <div className='comment-left-meta'>
                                    <h4 className='author-name'>
                                      Rosalina Kelian
                                    </h4>
                                    <div className='comment-date'>
                                      19th May 2018
                                    </div>
                                  </div>
                                </div>
                                <div className='comment-text'>
                                  <p>
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim laborumab. perspiciatis unde
                                    omnis iste natus error.
                                  </p>
                                </div>
                              </div>
                            </article>
                          </li>
                          <li className='article_comments_wrap'>
                            <article>
                              <div className='article_comments_thumb'>
                                <img
                                  src='https://via.placeholder.com/800x800'
                                  alt=''
                                />
                              </div>
                              <div className='comment-details'>
                                <div className='comment-meta'>
                                  <div className='comment-left-meta'>
                                    <h4 className='author-name'>
                                      Rosalina Kelian
                                    </h4>
                                    <div className='comment-date'>
                                      19th May 2018
                                    </div>
                                  </div>
                                </div>
                                <div className='comment-text'>
                                  <p>
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim laborumab. perspiciatis unde
                                    omnis iste natus error.
                                  </p>
                                </div>
                              </div>
                            </article>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Link to='/' className='reviews-checked text-primary'>
                      <i className='fas fa-arrow-alt-circle-down mr-2' />
                      See More Reviews
                    </Link>
                  </div>
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#nearby'
                    data-bs-target='#clNine'
                    aria-controls='clNine'
                    to='/'
                    aria-expanded='true'
                  >
                    <h4 className='property_block_title'>Nearby</h4>
                  </Link>
                </div>
                <div id='clNine' className='panel-collapse collapse show'>
                  <div className='block-body'>
                    {/* Schools */}
                    <div className='nearby-wrap'>
                      <div className='nearby_header'>
                        <div className='nearby_header_first'>
                          <h5>Schools Around</h5>
                        </div>
                        <div className='nearby_header_last'>
                          <div className='nearby_powerd'>
                            Powerd by
                            <img
                              src='assets/img/edu.png'
                              className='img-fluid'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                      <div className='neary_section_list'>
                        <div className='neary_section'>
                          <div className='neary_section_first'>
                            <h4 className='nearby_place_title'>
                              Green Iseland School<small>(3.52 mi)</small>
                            </h4>
                          </div>
                          <div className='neary_section_last'>
                            <div className='nearby_place_rate'>
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star' />
                            </div>
                            <small className='reviews-count'>
                              (421 Reviews)
                            </small>
                          </div>
                        </div>
                        <div className='neary_section'>
                          <div className='neary_section_first'>
                            <h4 className='nearby_place_title'>
                              Ragni Intermediate College<small>(0.52 mi)</small>
                            </h4>
                          </div>
                          <div className='neary_section_last'>
                            <div className='nearby_place_rate'>
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star-half filled' />
                            </div>
                            <small className='reviews-count'>
                              (470 Reviews)
                            </small>
                          </div>
                        </div>
                        <div className='neary_section'>
                          <div className='neary_section_first'>
                            <h4 className='nearby_place_title'>
                              Rose Wood Primary Scool<small>(0.47 mi)</small>
                            </h4>
                          </div>
                          <div className='neary_section_last'>
                            <div className='nearby_place_rate'>
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star' />
                            </div>
                            <small className='reviews-count'>
                              (204 Reviews)
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Hotel & Restaurant */}
                    <div className='nearby-wrap'>
                      <div className='nearby_header'>
                        <div className='nearby_header_first'>
                          <h5>Food Around</h5>
                        </div>
                        <div className='nearby_header_last'>
                          <div className='nearby_powerd'>
                            Powerd by
                            <img
                              src='assets/img/food.png'
                              className='img-fluid'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                      <div className='neary_section_list'>
                        <div className='neary_section'>
                          <div className='neary_section_first'>
                            <h4 className='nearby_place_title'>
                              The Rise hotel<small>(2.42 mi)</small>
                            </h4>
                          </div>
                          <div className='neary_section_last'>
                            <div className='nearby_place_rate'>
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                            </div>
                            <small className='reviews-count'>
                              (105 Reviews)
                            </small>
                          </div>
                        </div>
                        <div className='neary_section'>
                          <div className='neary_section_first'>
                            <h4 className='nearby_place_title'>
                              Blue Ocean Bar &amp; Restaurant
                              <small>(1.52 mi)</small>
                            </h4>
                          </div>
                          <div className='neary_section_last'>
                            <div className='nearby_place_rate'>
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star filled' />
                              <i className='fa fa-star' />
                            </div>
                            <small className='reviews-count'>
                              (40 Reviews)
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Write a Review */}
              <div className='property_block_wrap style-2'>
                <div className='property_block_wrap_header'>
                  <Link
                    data-bs-toggle='collapse'
                    data-parent='#comment'
                    data-bs-target='#clTen'
                    aria-controls='clTen'
                    to='/'
                    aria-expanded='true'
                  >
                    <h4 className='property_block_title'>Write a Review</h4>
                  </Link>
                </div>
                <div id='clTen' className='panel-collapse collapse show'>
                  <div className='block-body'>
                    <form className='form-submit'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='form-group'>
                            <textarea
                              className='form-control ht-80'
                              placeholder='Messages'
                              defaultValue=''
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='form-group'>
                            <select
                              id='ratting'
                              className='form-control border'
                            >
                              <option value>&nbsp;</option>
                              <option value={1}>01 Star</option>
                              <option value={2}>02 Star</option>
                              <option value={3}>03 Star</option>
                              <option value={4}>04 Star</option>
                              <option value={5}>05 Star</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Your Name'
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                          <div className='form-group'>
                            <input
                              type='email'
                              className='form-control'
                              placeholder='Your Email'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='form-group'>
                            <button
                              className='btn btn-primary fw-medium px-lg-5 rounded'
                              type='submit'
                            >
                              Submit Review
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* property Sidebar */}
            <div className='col-lg-4 col-md-12 col-sm-12'>
              {/* Like And Share */}
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Save
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='details-sidebar'>
                {/* Agent Detail */}
                <div className='sides-widget'>
                  <div className='sides-widget-header bg-primary'>
                    <div className='agent-photo'>
                      <img src='https://via.placeholder.com/800x800' alt='' />
                    </div>
                    <div className='sides-widget-details'>
                      <h4>
                        <Link to='/'>Shivangi Preet</Link>
                      </h4>
                      <span>
                        <i className='lni-phone-handset' />
                        (91) 123 456 7895
                      </span>
                    </div>
                    <div className='clearfix' />
                  </div>
                  <div className='sides-widget-body simple-form'>
                    <div className='form-group'>
                      <label>Email</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Your Email'
                      />
                    </div>
                    <div className='form-group'>
                      <label>Phone No.</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Your Phone'
                      />
                    </div>
                    <div className='form-group'>
                      <label>Description</label>
                      <textarea
                        className='form-control'
                        defaultValue={"I'm interested in this property."}
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-light-primary fw-medium rounded full-width'
                    >
                      Send Message
                    </button>
                  </div>
                </div>
                {/* Mortgage Calculator */}
                <div className='sides-widget'>
                  <div className='sides-widget-header bg-primary'>
                    <div className='sides-widget-details'>
                      <h4>Mortgage Calculator</h4>
                      <span>View your Interest Rate</span>
                    </div>
                    <div className='clearfix' />
                  </div>
                  <div className='sides-widget-body simple-form'>
                    <div className='form-group'>
                      <div className='input-with-icon'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Sale Price'
                        />
                        <i className='fa-solid fa-sack-dollar' />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='input-with-icon'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Down Payment'
                        />
                        <i className='fa-solid fa-piggy-bank' />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='input-with-icon'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Loan Term (Years)'
                        />
                        <i className='fa-regular fa-calendar-days' />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='input-with-icon'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Interest Rate'
                        />
                        <i className='fa fa-percent' />
                      </div>
                    </div>
                    <button
                      type='button'
                      className='btn btn-light-primary fw-medium rounded full-width'
                    >
                      Calculate
                    </button>
                  </div>
                </div>
                {/* Featured Property */}
                <div className='sidebar-widgets'>
                  <h4>Featured Property</h4>
                  <div className='sidebar_featured_property'>
                    {/* List Sibar Property */}
                    <div className='sides_list_property'>
                      <div className='sides_list_property_thumb'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                      <div className='sides_list_property_detail'>
                        <h4>
                          <Link to='/single-property-1'>
                            Loss vengel New Apartment
                          </Link>
                        </h4>
                        <span>
                          <i className='fa-solid fa-location-dot' />
                          Sans Fransico
                        </span>
                        <div className='lists_property_price'>
                          <div className='lists_property_types'>
                            <div className='property_types_vlix sale'>
                              For Sale
                            </div>
                          </div>
                          <div className='lists_property_price_value'>
                            <h4>$4,240</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* List Sibar Property */}
                    <div className='sides_list_property'>
                      <div className='sides_list_property_thumb'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                      <div className='sides_list_property_detail'>
                        <h4>
                          <Link to='/single-property-1'>
                            Montreal Quriqe Apartment
                          </Link>
                        </h4>
                        <span>
                          <i className='fa-solid fa-location-dot' />
                          Liverpool, London
                        </span>
                        <div className='lists_property_price'>
                          <div className='lists_property_types'>
                            <div className='property_types_vlix'>For Rent</div>
                          </div>
                          <div className='lists_property_price_value'>
                            <h4>$7,380</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* List Sibar Property */}
                    <div className='sides_list_property'>
                      <div className='sides_list_property_thumb'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                      <div className='sides_list_property_detail'>
                        <h4>
                          <Link to='/single-property-1'>
                            Curmic Studio For Office
                          </Link>
                        </h4>
                        <span>
                          <i className='fa-solid fa-location-dot' />
                          Montreal, Canada
                        </span>
                        <div className='lists_property_price'>
                          <div className='lists_property_types'>
                            <div className='property_types_vlix buy'>
                              For Buy
                            </div>
                          </div>
                          <div className='lists_property_price_value'>
                            <h4>$8,730</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* List Sibar Property */}
                    <div className='sides_list_property'>
                      <div className='sides_list_property_thumb'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                      <div className='sides_list_property_detail'>
                        <h4>
                          <Link to='/single-property-1'>
                            Montreal Quebec City
                          </Link>
                        </h4>
                        <span>
                          <i className='fa-solid fa-location-dot' />
                          Sreek View, New York
                        </span>
                        <div className='lists_property_price'>
                          <div className='lists_property_types'>
                            <div className='property_types_vlix'>For Rent</div>
                          </div>
                          <div className='lists_property_price_value'>
                            <h4>$6,240</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
