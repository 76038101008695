import React from 'react';
import { Link } from 'react-router-dom';

export default function Mumbai() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Mumbai</h2>
                  <p>
                    Mumbai, the capital city of Maharashtra, is India&apos;s
                    financial and commercial hub. Known for its fast-paced
                    lifestyle, diverse culture, and economic significance,
                    Mumbai offers a unique blend of tradition and modernity.
                  </p>
                  <img
                    src='https://upload.wikimedia.org/wikipedia/commons/1/14/Mumbai_Skyline_at_Night.jpg'
                    alt='Mumbai Cityscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    Formerly known as Bombay, Mumbai has a rich history as a
                    major port city during British colonial rule. Landmarks like
                    the Gateway of India and Chhatrapati Shivaji Maharaj
                    Terminus reflect its colonial past and architectural
                    grandeur.
                  </p>
                  <img
                    src='https://www.mistay.in/travel-blog/content/images/2021/07/Roam-around-the-top-7-historical-monuments-of-Mumbai-Chhatrapati-Shivaji-Terminus-I-MiStay.jpeg'
                    alt='Mumbai Heritage Site'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Situated on the western coast of India along the Arabian
                    Sea, Mumbai&apos;s strategic location has made it a pivotal
                    center for trade and commerce. Its natural harbor
                    facilitates maritime activities, contributing to its
                    economic prominence.
                  </p>
                  <img
                    src='https://bsmedia.business-standard.com/_media/bs/img/article/2024-01/09/full/1704799452-9048.jpg'
                    alt='Mumbai Coastline'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    Mumbai is home to major financial institutions,
                    multinational corporations, and the Bombay Stock Exchange.
                    The city&apos;s economy is diverse, encompassing sectors
                    like finance, entertainment, textiles, and technology.
                    Ongoing infrastructure projects, such as the Mumbai Metro
                    expansion and the Coastal Road Project, aim to enhance
                    connectivity and reduce congestion, further boosting real
                    estate prospects.
                  </p>
                  <img
                    src='https://marathon.in/wp-content/uploads/2022/05/blog_header-07-scaled.jpg'
                    alt='Mumbai Infrastructure'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Why Invest in Mumbai Real Estate?</h3>
                  <ul>
                    <li>
                      <strong>Robust Economic Environment:</strong> As
                      India&apos;s financial hub, Mumbai attracts businesses and
                      professionals, ensuring a steady demand for commercial and
                      residential properties.
                    </li>
                    <li>
                      <strong>Infrastructure Development:</strong> Projects like
                      the Mumbai Metro and new expressways are improving
                      connectivity, making more areas accessible and desirable.
                    </li>
                    <li>
                      <strong>High Rental Yields:</strong> Limited land
                      availability and high demand contribute to attractive
                      rental returns for investors.
                    </li>
                    <li>
                      <strong>Diverse Property Options:</strong> From luxury
                      apartments in South Mumbai to affordable housing in
                      emerging suburbs, the city offers investment opportunities
                      across various segments.
                    </li>
                    <li>
                      <strong>Cultural and Lifestyle Appeal:</strong>{' '}
                      Mumbai&apos;s vibrant arts scene, educational
                      institutions, and healthcare facilities make it a
                      preferred destination for many.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3 className='mt-5'>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
