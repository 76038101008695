import React from 'react';
import { Link } from 'react-router-dom';

export default function Aurangabad() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Chhatrapati Sambhajinagar (Aurangabad)</h2>
                  <p>
                    Chhatrapati Sambhajinagar, formerly known as Aurangabad, is
                    a city in Maharashtra renowned for its historical
                    significance and rapid industrial development. Home to
                    UNESCO World Heritage Sites like the Ajanta and Ellora
                    Caves, the city seamlessly blends cultural heritage with
                    modern growth.
                  </p>
                  <img
                    src='https://images.indianexpress.com/2021/01/Aurangabad.jpg'
                    alt='Historical Aurangabad'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    The city boasts a rich tapestry of history, evident in its
                    ancient monuments and architectural marvels. Attractions
                    such as the Bibi Ka Maqbara and Daulatabad Fort draw
                    tourists worldwide, enhancing the city&apos;s cultural
                    appeal.
                  </p>
                  <img
                    src='https://media2.thrillophilia.com/images/photos/000/367/228/original/1614084540_1553761744_shutterstock_289524986.jpg.jpg?w=753&h=450&dpr=1.5'
                    alt='Aurangabad Monuments'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Strategically located, Chhatrapati Sambhajinagar offers
                    excellent connectivity to major cities like Mumbai, Pune,
                    and Nashik. The development of the Samruddhi Mahamarg
                    Expressway has significantly reduced travel times,
                    bolstering the city&apos;s accessibility and appeal.
                  </p>
                  <img
                    src='https://www.researchgate.net/profile/Suddhasheel-Ghosh/publication/349302512/figure/fig1/AS:991107739561988@1613309907884/Geological-map-of-Aurangabad.ppm'
                    alt='Aurangabad Landscape'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    The city is emerging as an industrial hub, with sectors like
                    manufacturing, pharmaceuticals, and textiles driving its
                    economy. Initiatives such as the Aurangabad Industrial City
                    (AURIC) and the Delhi-Mumbai Industrial Corridor (DMIC) are
                    attracting investments, leading to job creation and
                    increased demand for residential and commercial properties.
                  </p>
                  <img
                    src='https://cdn.dnaindia.com/sites/default/files/styles/full/public/2019/01/15/778124-industry.jpg'
                    alt='Aurangabad Industrial Area'
                    className='img-fluid'
                  />

                  <h3 className='mt-5'>
                    Why Invest in Chhatrapati Sambhajinagar Real Estate?
                  </h3>
                  <ul>
                    <li>
                      <strong>Tourism Potential:</strong> The city&apos;s rich
                      heritage attracts tourists, boosting the hospitality
                      sector and creating opportunities for serviced apartments
                      and hotels.
                    </li>
                    <li>
                      <strong>Educational Institutions:</strong> Presence of
                      esteemed universities and colleges attracts students,
                      increasing demand for rental properties.
                    </li>
                    <li>
                      <strong>Affordable Property Prices:</strong> Compared to
                      other major cities, property prices here are competitive,
                      offering potential for appreciation.
                    </li>
                    <li>
                      <strong>Infrastructure Development:</strong> Ongoing
                      projects enhance connectivity and quality of life, making
                      it attractive for investors.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3 className='mt-5'>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/create-account' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
