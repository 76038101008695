import React from 'react';
import { Link } from 'react-router-dom';

export default function Pune() {
  return (
    <div>
      {/* ============================ Property Detail Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            {/* property main detail */}
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='property_block_wrap style-2 p-4'>
                <div className='prt-detail-title-desc'>
                  <h2>About Pune</h2>
                  <p>
                    Pune, often referred to as the Queen of the Deccan is a city
                    rich in history and culture. Located in the western Indian
                    state of Maharashtra, Pune has evolved from a prominent
                    cultural hub into a bustling metropolis known for its
                    educational institutions, IT parks, and vibrant lifestyle.
                  </p>
                  <img
                    src='https://www.travelingnext.com/wp-content/uploads/2023/01/pune-heritage-place.jpg'
                    alt='Historical Pune'
                    className='img-fluid'
                  />
                  <h3 className='mt-5'>Historical Significance</h3>
                  <p>
                    Punes history dates back to the 8th century, with
                    significant developments during the Maratha Empire in the
                    17th century. The city served as the headquarters of the
                    Peshwas, the prime ministers of the Maratha Empire, and has
                    numerous historical landmarks that reflect its glorious
                    past.
                  </p>
                  <img
                    src='https://www.fabhotels.com/blog/wp-content/uploads/2018/09/lal-mahal.jpg'
                    alt='Pune Heritage Site'
                    className='img-fluid'
                  />
                  <h3 className='mt-5'>Geographical Advantage</h3>
                  <p>
                    Nestled at the confluence of the Mula and Mutha rivers, Pune
                    enjoys a strategic location with a pleasant climate
                    throughout the year. Its proximity to the Western Ghats
                    offers scenic landscapes, while its elevation ensures
                    moderate temperatures, making it an attractive destination
                    for residents and investors alike.
                  </p>
                  <img
                    src='https://c8.alamy.com/comp/2WE7YEK/19-january-2024-cityscape-skyline-cityscape-of-pune-city-view-from-bopdev-ghat-pune-maharashtra-india-2WE7YEK.jpg'
                    alt='Pune Geography'
                    className='img-fluid'
                  />
                  <h3 className='mt-5'>Economic Growth and Infrastructure</h3>
                  <p>
                    Over the past few decades, Pune has witnessed rapid economic
                    growth, emerging as a major IT and industrial hub. The
                    establishment of the Rajiv Gandhi Infotech Park in Hinjewadi
                    and the presence of numerous multinational companies have
                    spurred demand for commercial and residential properties.
                    Additionally, infrastructure projects like the Pune Metro
                    and improved road networks have enhanced connectivity,
                    further boosting real estate prospects.
                  </p>
                  <img
                    src='https://www.panchshil.com/assets/images/banners/yEFLphCRv9J3CbdEd5IoBgTX9C34wd.webp'
                    alt='Pune IT Park'
                    className='img-fluid'
                  />
                  <h3 className='mt-5'>Why Invest in Pune Real Estate?</h3>
                  <ul>
                    <li>
                      <strong>Thriving IT and Industrial Sectors:</strong> The
                      influx of professionals has increased demand for quality
                      housing.
                    </li>
                    <li>
                      <strong>Educational Institutions:</strong> Home to
                      prestigious universities and colleges, attracting students
                      nationwide.
                    </li>
                    <li>
                      <strong>Infrastructure Development:</strong> Ongoing
                      projects are set to improve connectivity and quality of
                      life.
                    </li>
                    <li>
                      <strong>High Rental Yields:</strong> Growing population
                      ensures steady rental income for investors.
                    </li>
                    <li>
                      <strong>Quality of Life:</strong> A blend of urban
                      amenities and cultural heritage offers a balanced
                      lifestyle.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='like_share_wrap b-0'>
                <ul className='like_share_list'>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Share'
                    >
                      <i className='fas fa-share' />
                      Share
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/'
                      className='btn btn-likes'
                      data-toggle='tooltip'
                      data-original-title='Save'
                    >
                      <i className='fas fa-heart' />
                      Like
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Property Detail End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
