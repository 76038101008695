import React from 'react';
import { Link } from 'react-router-dom';

export default function ListingListWithSidebar() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Property List with Sidebar</h2>
              <span className='ipn-subtitle'>Property List With Sidebar</span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ All Property ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='filter_search_opt'>
                <Link
                  to='/'
                  className='btn btn-dark full-width mb-4'
                  onClick='openFilterSearch()'
                >
                  <span className='svg-icon text-light svg-icon-2hx me-2'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                        fill='currentColor'
                      />
                    </svg>{' '}
                  </span>
                  Open Filter Option
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* property Sidebar */}
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div
                className='simple-sidebar sm-sidebar'
                id='filter_search'
                style={{ left: 0 }}
              >
                <div className='search-sidebar_header'>
                  <h4 className='ssh_heading'>Close Filter</h4>
                  <button
                    type='button'
                    onClick='closeFilterSearch()'
                    className='w3-bar-item w3-button w3-large'
                  >
                    <i className='fa-regular fa-circle-xmark fs-5 text-muted-2' />
                  </button>
                </div>
                {/* Find New Property */}
                <div className='sidebar-widgets'>
                  <div className='search-inner p-0'>
                    <div className='filter-search-box'>
                      <div className='form-group'>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control rounded-3 ps-5'
                            placeholder='Search by space name…'
                          />
                          <div className='position-absolute top-50 start-0 translate-middle-y ms-2'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.5'
                                  x='17.0365'
                                  y='15.1223'
                                  width='8.15546'
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 17.0365 15.1223)'
                                  fill='currentColor'
                                />
                                <path
                                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative d-flex flex-xl-row flex-column align-items-center'>
                      <div className='verifyd-prt-block flex-fill full-width my-1 me-1'>
                        <div className='d-flex align-items-center justify-content-center justify-content-between border rounded-3 px-2 py-3'>
                          <div className='eliok-cliops d-flex align-items-center'>
                            <span className='svg-icon text-success svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                                  fill='currentColor'
                                />
                              </svg>{' '}
                            </span>
                            <span className='text-muted-2 fw-medium ms-1'>
                              Verified
                            </span>
                          </div>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckChecked'
                              defaultChecked
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexSwitchCheckChecked'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='super-agt-block flex-fill full-width my-1 ms-1'>
                        <div className='d-flex align-items-center justify-content-center justify-content-between border rounded-3 px-2 py-3'>
                          <div className='eliok-cliops d-flex align-items-center'>
                            <span className='svg-icon text-warning svg-icon-2hx'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  d='M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z'
                                  fill='white'
                                />
                              </svg>{' '}
                            </span>
                            <span className='text-muted-2 fw-medium ms-1'>
                              SuperAgent
                            </span>
                          </div>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckChecked'
                              defaultChecked
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexSwitchCheckChecked'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='filter_wraps'>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#where'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Where<span className='selected'>Chicago</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='where'
                          data-parent='#where'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='b1'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b1'
                                        className='form-check-label'
                                      >
                                        Atlanta
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b2'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b2'
                                        className='form-check-label'
                                      >
                                        Austin
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b3'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b3'
                                        className='form-check-label'
                                      >
                                        Boston
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b4'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='b4'
                                        className='form-check-label'
                                      >
                                        Chicago
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b5'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b5'
                                        className='form-check-label'
                                      >
                                        Dallas
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b6'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b6'
                                        className='form-check-label'
                                      >
                                        Denver
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b7'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b7'
                                        className='form-check-label'
                                      >
                                        Houston
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b8'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b8'
                                        className='form-check-label'
                                      >
                                        Jacksonville
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b9'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b9'
                                        className='form-check-label'
                                      >
                                        Los Angeles
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fptype'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Property Types
                              <span className='selected'>Apartment</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fptype'
                          data-parent='#fptype'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='c1'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c1'
                                        className='form-check-label'
                                      >
                                        House
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c2'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c2'
                                        className='form-check-label'
                                      >
                                        Office Desk
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c3'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c3'
                                        className='form-check-label'
                                      >
                                        Villa
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c4'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='c4'
                                        className='form-check-label'
                                      >
                                        Apartment
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c5'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c5'
                                        className='form-check-label'
                                      >
                                        Condo
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c6'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c6'
                                        className='form-check-label'
                                      >
                                        Denver
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c7'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c7'
                                        className='form-check-label'
                                      >
                                        Studio
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fbedrooms'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Bedrooms<span className='selected'>2 Beds</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fbedrooms'
                          data-parent='#fbedrooms'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='a1'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a1'
                                        className='form-check-label'
                                      >
                                        01 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a2'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a2'
                                        className='form-check-label'
                                      >
                                        02 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a3'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a3'
                                        className='form-check-label'
                                      >
                                        03 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a4'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='a4'
                                        className='form-check-label'
                                      >
                                        04 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a5'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a5'
                                        className='form-check-label'
                                      >
                                        05 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a6'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a6'
                                        className='form-check-label'
                                      >
                                        06+ Bedrooms
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fprice'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Price Range
                              <span className='selected'>
                                $10,000 - $15,000
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fprice'
                          data-parent='#fprice'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='e1'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e1'
                                        className='form-check-label'
                                      >
                                        Less Then $10,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e2'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e2'
                                        className='form-check-label'
                                      >
                                        $10,000 - $15,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e3'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e3'
                                        className='form-check-label'
                                      >
                                        $12,000 - $25,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e4'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='e4'
                                        className='form-check-label'
                                      >
                                        $30,000 - $35,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e5'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e5'
                                        className='form-check-label'
                                      >
                                        $40,000 - $45,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e6'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e6'
                                        className='form-check-label'
                                      >
                                        $50,000 - $55,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e7'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e7'
                                        className='form-check-label'
                                      >
                                        $60,000 - $65,000
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#mood'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Mood<span className='selected'>Any Mood</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='mood'
                          data-parent='#mood'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='f1'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f1'
                                        className='form-check-label'
                                      >
                                        Any Mood
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f2'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f2'
                                        className='form-check-label'
                                      >
                                        Professional
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f3'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f3'
                                        className='form-check-label'
                                      >
                                        Essentials
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f4'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='f4'
                                        className='form-check-label'
                                      >
                                        Unique
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f5'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f5'
                                        className='form-check-label'
                                      >
                                        Lively
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f6'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f6'
                                        className='form-check-label'
                                      >
                                        Luxe
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f7'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f7'
                                        className='form-check-label'
                                      >
                                        Luxe
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#ameneties'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Ameneties
                              <span className='selected'>ADA Compliant</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='ameneties'
                          data-parent='#ameneties'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='g1'
                                        className='form-check-input'
                                        name='ADA'
                                        type='checkbox'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='g1'
                                        className='form-check-label'
                                      >
                                        ADA Compliant
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g2'
                                        className='form-check-input'
                                        name='Parking'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g2'
                                        className='form-check-label'
                                      >
                                        Parking Options
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g3'
                                        className='form-check-input'
                                        name='Coffee'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g3'
                                        className='form-check-label'
                                      >
                                        Coffee Provided
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g4'
                                        className='form-check-input'
                                        name='Mother'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g4'
                                        className='form-check-label'
                                      >
                                        Mothers Room
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g5'
                                        className='form-check-input'
                                        name='Outdoor'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g5'
                                        className='form-check-label'
                                      >
                                        Outdoor Space
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g6'
                                        className='form-check-input'
                                        name='Pet'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g6'
                                        className='form-check-label'
                                      >
                                        Pet Friendly
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g7'
                                        className='form-check-input'
                                        name='Beauty'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g7'
                                        className='form-check-label'
                                      >
                                        Beauty &amp; Message
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g8'
                                        className='form-check-input'
                                        name='Bike'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g8'
                                        className='form-check-label'
                                      >
                                        Bike Parking
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g9'
                                        className='form-check-input'
                                        name='Phone'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g9'
                                        className='form-check-label'
                                      >
                                        Phone Line
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g11'
                                        className='form-check-input'
                                        name='Private'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g11'
                                        className='form-check-label'
                                      >
                                        Private Areas
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g12'
                                        className='form-check-input'
                                        name='Free'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g12'
                                        className='form-check-label'
                                      >
                                        Free WiFi
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g13'
                                        className='form-check-input'
                                        name='Swiming'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g13'
                                        className='form-check-label'
                                      >
                                        Swiming Pool
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-group filter_button'>
                      <button
                        type='submit'
                        className='btn btn btn-primary rounded full-width'
                      >
                        22 Results Show
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sidebar End */}
            </div>
            <div className='col-lg-8 col-md-12 list-layout'>
              <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-12'>
                  <div className='item-shorting-box'>
                    <div className='item-shorting clearfix'>
                      <div className='left-column pull-left'>
                        <h4 className='fs-6 m-0'>Found 1-10 of 142 Results</h4>
                      </div>
                    </div>
                    <div className='item-shorting-box-right'>
                      <div className='shorting-by'>
                        <select id='shorty' className='form-control'>
                          <option value>&nbsp;</option>
                          <option value={1}>Low Price</option>
                          <option value={2}>High Price</option>
                          <option value={3}>Most Popular</option>
                        </select>
                      </div>
                      <ul className='shorting-list'>
                        <li>
                          <Link
                            to='/grid-layout-with-sidebar'
                            className='w-12 h-12'
                          >
                            <span className='svg-icon text-muted-2 svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x={2}
                                  y={2}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={13}
                                  y={2}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={13}
                                  y={13}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={2}
                                  y={13}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/listings-list-with-sidebar'
                            className='active w-12 h-12'
                          >
                            <span className='svg-icon text-seegreen svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M14 10V20C14 20.6 13.6 21 13 21H10C9.4 21 9 20.6 9 20V10C9 9.4 9.4 9 10 9H13C13.6 9 14 9.4 14 10ZM20 9H17C16.4 9 16 9.4 16 10V20C16 20.6 16.4 21 17 21H20C20.6 21 21 20.6 21 20V10C21 9.4 20.6 9 20 9Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M7 10V20C7 20.6 6.6 21 6 21H3C2.4 21 2 20.6 2 20V10C2 9.4 2.4 9 3 9H6C6.6 9 7 9.4 7 10ZM21 6V3C21 2.4 20.6 2 20 2H3C2.4 2 2 2.4 2 3V6C2 6.6 2.4 7 3 7H20C20.6 7 21 6.6 21 6Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center'>
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Adobe Property Advisors
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (42 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $120M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Agile Real Estate Group
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (34 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $132M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Bluebell Real Estate
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (124 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $127M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Strive Partners Realty
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (56 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $132M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Agile Real Estate Group
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (16 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $117M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-success text-success d-inline-flex mb-1'>
                              For Rent
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Nestled Real Estate
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (123 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $7,500
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
                {/* Single Property Start */}
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='property-listing property-1 bg-white p-2 rounded'>
                    <div className='listing-img-wrapper'>
                      <Link to='/single-property-2'>
                        <img
                          src='https://via.placeholder.com/1200x850'
                          className='img-fluid mx-auto rounded'
                          alt=''
                        />
                      </Link>
                    </div>
                    <div className='listing-content'>
                      <div className='listing-detail-wrapper-box'>
                        <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                          <div className='listing-short-detail'>
                            <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                              For Sale
                            </span>
                            <h4 className='listing-name mb-0'>
                              <Link to='/single-property-2'>
                                Flow Group Real Estate
                              </Link>
                            </h4>
                            <div className='fr-can-rating'>
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <i className='fas fa-star fs-xs filled' />
                              <span className='reviews_text fs-sm text-muted ms-2'>
                                (42 Reviews)
                              </span>
                            </div>
                          </div>
                          <div className='list-price'>
                            <h6 className='listing-card-info-price text-primary'>
                              $112M
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-footer-wrapper'>
                        <div className='listing-locate'>
                          <span className='listing-location text-muted-2'>
                            <i className='fa-solid fa-location-pin me-1' />
                            Quice Market, Canada
                          </span>
                        </div>
                        <div className='listing-detail-btn'>
                          <Link
                            to='/single-property-2'
                            className='btn btn-sm px-4 fw-medium btn-primary'
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Property End */}
              </div>
              {/* Pagination */}
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <ul className='pagination p-center'>
                    <li className='page-item'>
                      <Link className='page-link' to='/' aria-label='Previous'>
                        <i className='fa-solid fa-arrow-left-long' />
                        <span className='sr-only'>Previous</span>
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        1
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        2
                      </Link>
                    </li>
                    <li className='page-item active'>
                      <Link className='page-link' to='/'>
                        3
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        ...
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        18
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/' aria-label='Next'>
                        <i className='fa-solid fa-arrow-right-long' />
                        <span className='sr-only'>Next</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Property ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20am%20interested%20in%20becoming%20a%20real%20estate%20agent'
                  className='btn btn-call-to-act'
                >
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
